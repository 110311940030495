.mainpage{
    display:flex;
    flex-direction:row;
    height:100%;
}
#sidebar2{
 transform:translate(-100vw) ;
 overflow-y:auto;
 transition:0.25s ease-in-out;
 display:none;
}
.sidebar{
    display:flex;
    font-family: 'Poppins';
    flex-direction:column;
    background-color:#000 !important;
    flex-wrap: wrap;
    padding-left:0;
    position:absolute;
    top:1.5vw;
    bottom:1.5vw;
    left:2vw;
    border-radius: 1.16vw;
    padding:15px 15px 15px 17px;
    border:1px solid grey;
    z-index:2;
    transition:0.5s ease-in;
}
#sidebar1{
    display:flex;
    font-family: 'Poppins';
    flex-direction:column;
    background-color:#000 !important;
    flex-wrap: wrap;
    padding-left:0;
    position:absolute;
    top:1.5vw;
    bottom:1.5vw;
    left:2vw;
    border-radius: 1.16vw;
    padding:15px 15px 15px 17px;
    border:1px solid grey;
    z-index:2;
    transition:0.5s ease-in;
}
#arrow2{
    display:none;
}
.expanded-1{
    display:none;
    color:white;
    font-size:1vw;
    font-weight: 600;
}
.expanded-2{
    color:white;
    font-size:1vw;
    font-weight: 600;
}

.expanded,.expanded_2{
    display:flex;
    gap:0.3vw;
}
.expanded{
    padding:0.75vw;
    cursor:pointer;
}

.expanded_1:hover{
    background:#2E3238;
    border-radius:5px;
    margin:0 5px;
}
a{
    text-decoration: none !important;
}


.sideNavButton{
    padding:20px;
    padding-right:20px;
}
.dashboard{
    background-image: url('../assets/Noise.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Poppins';
    width:100%;
    display:flex;
    flex-direction:column;
}
.navbar{
    width:100%;
    padding:3vw 3vw 3vw 3vw!important;
    border-radius:1.16vw;
}
.riht{
    position:relative;
    right:5%;
    display:flex;
    padding-left: 1vw;
    flex-direction:row;
    justify-content:space-between;
   
}
.riht div{
    margin:auto 10%;
    display:inline-block;
    text-align:center;
}
.wallet {
    text-align:center;
}
[data-rk] .ju367v93 {
    background-color: transparent;
    font-size:20px;
    font-weight:400;
    border-radius: 0.578vw;
    border: 1px solid #FFF;
    background: rgba(0, 0, 0, 0.11);
    box-shadow: 4px 4px 0px 0px #12D576;
}
[data-rk]{
    height:100%;
}
.iekbcc0.ju367va.ju367vz.ju367v10.ju367v6j{
    position:fixed;
    top:4px;
}

[data-rk] ._9pm4ki3{
    top:0px;
}
/* @media only screen and (max-width:592px){
    .navBrand{
        display:none;
    }
    .navbar{
        margin:0 auto;
    }
} */
.rihtLink{
    padding-left:10px;
    padding-right:20px;
}
.rihtLink:hover{
    background-color:#12D576 !important;
    border-radius:20px;
}
/* ConnectButton{
    background-color:#12D576 !important;
    border-radius:20px;
} */
@media only screen and (max-width:2158px){
    .dashboard{
        padding-left:151px !important;
    }
}
@media only screen and (max-width:1100px){
    #comp_name{
        display:none !important;
    }
}
@media only screen and (max-width:1218px){
    svg{
        width:18px !important;
        height:18px !important;
    }
    #logo_1{
        width:39px !important;
        height:33px !important;
    }
    #logo_2{
        height:48.75px !important;
    }
    #sidebar1{
        left:10px !important;
    }
    #arrow1,#arrow2{
        width:25px !important;
        height:25px !important;
    }
    .expanded-1,.expanded-2{
        font-size:12.18px !important;
    }
    .expanded_3{
        font-size:18.27px !important;
    }
    .dashboard{
        padding-left: 85.26px !important;
    }
}

@media only screen and (max-width:1000px){
    .navbar{
        margin-bottom:5vw;
    }
}

@media only screen and (max-width:945px){
    #contact{
        display: none;
    }
}

@media only screen and (max-width:700px){
    #sales,#pricing{
      padding:0 !important;
    }
    #sales1,#pricing1{
      display:none !important;
    }
    #bars{
      display:block !important;
      position:relative !important;
      left:10px !important;
      top:9.5vw !important;
      z-index:1 !important;
      width:5vw;
      height:5vw;
    }
    #sidebar1{
      display:none !important;
    }
    #sidebar2{
      display:block !important;
    }
    .themeic{
        display:none !important;
    }
    .offcanvas{
        width:125px !important;
        background-color: #101010;
    }
    .dashboard{
        padding-left:0px !important;
    }
    .home{
        padding-left: 15px !important;
    }
  }

@media only screen and (max-width:600px){
    #comp_name{
        display:none;
    }
}
@media only screen and (max-width:500px){
    #bars{
        top:3.5vw !important;
    }
    #bars svg{
        width:30px !important;
        height:30px !important;
    }
    /* .navBrand{
        display:none;
    } */
    .dropdown{
        margin:0 !important;
    }
    #sales,#pricing{
        display:none;
    }
    #sales1,#pricing1{
        display:block !important;
        text-decoration:none !important;
    }
    .riht{
        position:absolute !important;
        right:1.5vw !important;
    }
    #price_logo,#sale_logo{
        display:flex !important;
    }
    
}
@media (min-width: 100px) and (max-width: 500px){
    #logo_img{
        width: 68px !important;
        margin-left: 36px;
        height: 60px !important;
    }
    #bars svg {
        width: 36px !important;
        margin-top: 18px;
        height: 49px !important;
    }
}
@media (min-width: 500px) and (max-width: 700px){
    #logo_img{
        width: 53px !important;
        height: 46px !important;
        margin-top: -8px;
        margin-left: 49px !important;
    }
    #bars svg {
        width: 36px !important;
        margin-top: -16px;
        height: 49px !important;
    }
}