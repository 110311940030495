.body-image{
    height: 250px 
}
table{
    width:100%;
    text-align: center;
}
td,th{
    border:2px solid #464646 !important;
}
@media only screen and (max-width:1333px){
    .body-image{
        height:150px !important;
    }
    .buysale-social svg{
        width:1.6vw !important;
        height:1.6vw !important;
    }
    #audit_kyc img{
        width:18px !important
    }
}
@media only screen and (max-width:1200px){
    .buysale-back{
        padding-top:0.3vw !important;
    }
}
@media only screen and (max-width:1000px){
    .tokenSale1{
        justify-content: center !important;
    }
    .buysale-back{
        padding-top:0.1vw !important;
    }
    .auditLink,.kycLink,.vettedLink{
        height:28.5px !important;
        font-size:15px !important;
        padding:0 3px !important;
    }
    .auditLink svg,.kycLink svg,.vettedLink svg{
        height:11px !important;
        width:11px !important;
    }
    .buysale-social svg{
        width:16px !important;
        height:16px !important;
    }
}


@media only screen and (max-width:830px){
    .body-image{
        height:120px !important;
    }
    .expected-token{
        font-size:15px !important;
    }
    .buysale-social svg{
        width:1.9vw !important;
        height:1.9vw !important;
    }
}
@media only screen and (max-width:800px){
    .buysale-back{
        padding-top:0vw !important;
        position:relative;
        bottom:2px;
    }
    
}

@media only screen and (max-width:769px){
    .tokenSale1{
        padding:1vw 0 !important; 
    }
    .main-body{
        width:400px !important;
    }
    .buysale-desc{
        font-size:15px !important;
    }
    .buysale-sale{
        font-size:15px !important;
    }
}

@media only screen and (max-width:756px){
    .buysection-parent{
        width:400px !important;
    }
    .buysection-status{
        font-size:15px !important;
    }
    .buysection-soft{
        font-size:15px !important;
    }
    .buysection-input{
        font-size:12px !important;
    }
    .buysection-input input{
        font-size:12px !important;
        width:180px !important;
    }
    .buysale-social svg{
        width:16px !important;
        height:16px !important;
    }
    .buysale-head{
        padding-top:15px !important;
        font-size:18px !important;
    }
    .buysale-back1{
        font-size: 15px !important;
    }
    .buysale-back svg{
        width:15px !important;
        height:15px !important;
    }

}

@media only screen and (max-width:700px){
    .buysale-back{
        padding-top:0vw !important;
        position:relative !important;
        bottom:3px !important;
    }
    
}
@media only screen and (max-width:650px){
    .body-image{
        height:100px !important;
    }
    .buysale-back{
        padding-top:0vw !important;
        position:relative !important;
        bottom:4px !important;
    }
    
}
@media only screen and (max-width:600px){
    .buysale-back svg{
        margin-top:4px !important;
    }
    
}
@media only screen and (max-width:550px){
    .buysale-back svg{
        margin-top:6px !important;
    }   
    
}
@media only screen and (max-width:500px){
    .buysale-back{
        padding-top:0vw !important;
        position:relative !important;
        bottom:7px !important;
    }
}
@media only screen and (max-width:450px){
    .buysale-back svg{
        margin-top:10px !important;
    }
    
}
@media only screen and (max-width:400px){
    .buysale-sale{
        font-size: 12px !important;
    }
    .buysection-parent{
        width:98% !important;
    }
    .main-body{
        width:98% !important;
    }
    
}
