.container {
  padding: 10px;  
}

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 2vw;
    padding: 0 2vw;
    overflow-y: scroll;
    height: 700px;
    margin-top: 20px;
  }
  
  #indicator{
    width:8px !important;
    height:8px !important;
  }
  .card {
    width: 340px;
    border-radius: 15px !important;
    transition: transform 0.2s ease-in-out;
    background-color:#000 !important;
    border:1px solid #00FF83 !important;
  }
 
  

  .card:hover {
    transform: translateY(-7px);
  }
  
  .card-image {
    width:100%;
    height:140px;
    border-radius: inherit;
    margin-bottom: -15px;
    
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
   height:100%;
   padding:0 19.2px;
  }
  
  .card-title {
    font-size: 38.5px;
    padding-top: 19.2px;
    text-align: left;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-bottom: -5px ;
    color:antiquewhite
  }
  
  .card-description {
    font-size: 15px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #fcfbffd7;
    text-align: left;
    margin-top: -10px ;
  }
  
/* progress bar text details */

  .progress-text {
    display: block;
    margin-bottom: 5px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    margin-top:-8px;
    font-weight: 600;
    color: antiquewhite;
  }
  
  .value-text {
    margin-left: 10px;
    font-size: small; 
  font-style: bold;
  font-weight: 600;
  color: antiquewhite;
  }

  .new-text {
    margin-left: 150px;
    font-size: small; 
  font-style: bold;
  font-weight: 600;
  color: antiquewhite;
  }

  /* Progress bar details */

  .progress-bar-container {
    width: 100%;
    height: 15.5px;
    margin-bottom: 19.2px;
  }
  
  .progress-bar {
    width: 100%;
    height: 80%;
    border-radius: 10px;
    margin-right: 20px;
    color: #464646;
  }
  
  .progress-bar::-webkit-progress-value {
    border-radius: inherit !important;
    background:linear-gradient(90deg, #00EC79 0%, #00D9F5 100%) !important; /* Customize the color of the progress bar */
  }
  
  .progress-bar::-webkit-progress-bar {
    background-color: #767676; /* Customize the background color of the progress bar */
  }

/* box details for softcap */

   .box-container {
    display: flex;
    flex-direction:row;
    justify-content:space-around;
    flex-wrap: wrap; 
  }
  
  .box {
    width:47%;
    padding:10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:15px;
    margin-top: 15px;
    border: 2px solid;
  }
  
  .box-text {
    color: #3516d3;
    border-radius: 25px;
  }
  
  .box-1 {
    border-color: #00FF83 !important;
    
  }
  
  .box-2 {
    border-color: #00FF83 !important;
    
  }
  
  .box-3 {
    border-color: #00FF83 !important;
    
  }

  .box-4 {
    border-color: #00FF83 !important;
  }
  
  .box-1 .box-text {
    color: #faf7f7; /* Change the text color to contrast with box-1 background color */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: small;
  }
  
  .box-2 .box-text {
    color: #faf7f7; 
    /* Change the text color to contrast with box-2 background color */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: small;
  }
  
  .box-3 .box-text {
    color: #faf7f7; /* Change the text color to contrast with box-3 background color */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: small;
  }

  .box-4 .box-text {
    color: #faf7f7; /* Change the text color to contrast with box-3 background color */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: small;
    
  }

  .Softcap-text{

    color: #66f749; /* Change the text color to contrast with box-3 background color */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: small;
    margin-left: 5px;
  }

  .Liquidity-text{
    color: #66f749; /* Change the text color to contrast with box-3 background color */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: small;
    margin-left: 5px;
  }
  
  .Locked-text{
    color: #66f749; /* Change the text color to contrast with box-3 background color */
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: small;
    margin-left: 5px;
  }
  
.line {
  border-bottom:1px solid #ffffff;
  padding-bottom: 50px; /* Optional: Add spacing below the line */
  margin-bottom: 60px;
  margin-top:-35px;
}

.end{
 margin-left: 10px;
 padding-bottom: 10px;
 margin-top: -50px;
 font-size: 13px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffffd7;
    text-align: left;
    font-weight:bold;
}
  
.icon-container {
  display: flex;
  align-items: center;
  margin-left: 190px;
  margin-top: -35px;
  margin-bottom: 15px;
}

.icon-container span {
  font-size: 28px; /* Adjust the font size as needed */
  margin-right: 10px; /* Adjust the spacing between icons as needed */
  color:#ffffff;
}
.note{
  display: flex !important;
  gap:15px !important;
}


/* ==================================================== Search Box ========================================== */

.searchpage-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

.search_box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 91%;
  flex-wrap: wrap;
}

.s_input{
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 2px solid white;
  align-items: center;
  padding: 9px;
  border-radius: 25px;
}
.s_input input{
  background: #2b649500;
  border: none;
  color: white;
}
.s_input input:focus {
  outline: none;
}
.s_input button{
  background: #2b649500;
  border: none;
}
.d_input{
  padding: 9px;
  border-radius: 27px;
  border: 2px solid white;
  background-color: #00ffff00;
  color: white;
  margin-left: 11px;
}

.d_input {
  background-color: #0000;
  border-radius: 26px;
  border: 2px solid white;
  color: white;
  cursor: pointer;
}

.d_input option {
  background-color: #000;
  color: white;
  border: 2px solid;
  border-radius: 10px;
}

/* Add a white outline to the option when focused or selected */
.d_input option:hover,
.d_input option:focus,
.d_input option:selected {
  outline: 1px solid white;
  background-color: #000;
  color: white;

}


.card-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit-based browsers (Chrome, Safari) */
}

/* For Firefox */
.card-container {
  scrollbar-width: none;
}

/* For Internet Explorer and Edge */
.card-container {
  -ms-overflow-style: none;
}

@media (max-width: 770px) {
  .search_box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }
  .s_input{
    margin-bottom: 15px;
  }
}

/* For screen sizes between 770px and 933px */
@media screen and (min-width: 770px) and (max-width: 933px) {
  .search_box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }
  .s_input{
    margin-bottom: 15px;
  }
}

/* For screen sizes between 770px and 933px */
@media screen and (min-width: 999px) and (max-width: 1169px) {
  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 78% !important;
    padding: 14px;
    flex-wrap: wrap;
}
}