.lockTokenFirstRow{
    display:flex;
    flex-direction:row;
    height:100%;
}
@media only screen and (max-width:650px){
.locktoken1{
    height:40px !important;
}
}
@media only screen and (max-width:610px){
    .locktoken{
        margin:0 !important;
    }
}
@media only screen and (max-width:426px){
    .lockTokenFirstRow_1{
        padding-left:0!important;
    }
    .lockTokenSecondRow{
        width:142% !important;
    }
}
@media only screen and (max-width:435px){
   
    .lockTokenSecondRow div{
        font-size: 15px !important;
    }
    .lockTokenSecondRow button{
        font-size: 17px !important;
    }
    .lockTokenSecondRow svg{
        width:15px !important;
        height:15px !important;
    }
}
