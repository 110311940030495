.home{
    display:flex;
    flex-direction:row;
    padding-left:3vw;
}
#unleash{
    font-size:2.5vw;
}
#unleash_para{
    font-size:1vw;
}
#home_img{
width:35vw;
}
@media  only screen and (max-width:1230px) {
    #unleash{
        font-size:3.5vw;
    }
    #unleash_para{
        font-size:1.5vw;
    }
    #home_img{
        width:40vw;
    }
}
@media  only screen and (max-width:1000px) {
    #unleash{
        font-size:4.5vw !important;
    }
    #unleash_para{
        font-size:2.5vw !important;
        padding-right:5vw !important;
    }
    #home_img{
        width:100% !important;
    }
    .home{
        flex-wrap:wrap-reverse;
    }
}
@media only screen and (max-width:770px){
    #unleash{
        font-size:5.5vw;
    }
    #unleash_para{
        font-size:3.5vw;
    }
    #home_img{
        width:55vw;
    }
}
@media only screen and (max-width:700px){
    #unleash{
        font-size:6.5vw !important;
    }
    #unleash_para{
        font-size:4.5vw !important;
    }
}