
.createtoken-button{
    background-color: #4CAF50; /* Green background */
    border: none; /* Remove border */
    color: white; /* White text color */
    padding: 12px 24px; /* Add padding */
    text-align: center; /* Center text */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Make it an inline element */
    font-size: 16px; /* Font size */
    border-radius: 4px; /* Add rounded corners */
    cursor: pointer; /* Add cursor on hover */
    transition: background-color 0.3s ease; /* Add smooth transition */  
}

.createtoken-button:hover {
    background-color: #a53c0b; /* Darker green background on hover */
  }

input[type=radio] {
    accent-color:#12D576;
    width: 1rem;
    height: 1rem;
    background-color: black;
    border: 0.15em solid white;
    border: 2px solid white;
}
.tokenBody{
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-around;
}
.tokenFirstRow{
    display:flex;
    flex-direction:column;
    padding:7% 10%;
    padding-left:0;
    gap:10px;
}
.tokenSecondRow{
   display:flex;
   flex-direction:column;
   padding:7% 10%;
   padding-left:0;
   gap:10px;
}
.tokenCoin{
    display:flex;
    flex-direction:row;
}
.tokenCoin .tokenFirstRow button{
   background-color:transparent;
  color:#464646;
  border:1px solid #464646;
  border-radius:14px;
  /* padding:1% 2%; */
}
.tokenCoin .tokenSecondRow button{
    background-color:transparent;
    color:#464646;
    border:1px solid #464646;
    border-radius:14px;
    /* padding:1% 2%; */
}
.tokenSettings{
    background-color:#2D5C8F1C;
    opacity:0.75;
    border:1px solid #2D5C8F1C;
    padding:3%;
}
.tokenButton{
    padding-left:24px;
    padding-right:24px;
    padding-top:10px;
    padding-bottom:10px;
}

