#drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .drop-message {
    font-size: 16px;
    color: #888;
  }
  
  .drop-message:hover {
    color: #555;
  }
  
  