.head{
    padding-bottom: 2%;
    border-bottom: 1px solid white;
    color:white;
    margin-left:2%;
    margin-right:2%;
    font-weight:700;
    margin-bottom:3%;
}
.price-section{
    
    margin-bottom:6%;
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    flex-wrap: wrap;
}
.price-section-items{
    width:20%;
    height:50%;
    border-radius:1%;
    text-align:center;
}
p{
    font-size:4 rem;
    font-weight: 500;
    color:#8f8f8f;
}
.topic{
    background-color: #139a6d;
    margin:0;
    padding-top:20px;
    padding-bottom:20px;
}

@media screen and (max-width:991px) and (min-width:768px){
    .price-section-items{
        width:47%;
        margin-bottom:2%;
    }
}
@media screen and (max-width:768px){
    .price-section-items{
        width:80%;
        margin-bottom:2%;
    }
}