
.contact-heading {
  color: #00FF83;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-bottom: none;
  font-size: 37px;
  text-align: center;
  font-style:normal;
  font-weight: 700;
  line-height: 72px;
  margin-left: -40px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
 
}


.box-container1 {
  border: 1px solid lightgreen;
  background-color: black;
  padding: 10px; /* Add padding as desired */
  margin-top: 10px; 
  width: 900px; 
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center; 
  border-radius: 20px;
  margin-left: 170px;
  
}

.inner-container {
  width: 322px; 
  height: 598px;
  border: 1px solid hwb(189 0% 0% / 0.773);
  border-top-width: 0.5px;
  border-right-width: 2px;
  margin-left: -227px;
  /* margin-right: 190px; */
  border-radius: 20px;
  /* margin-top:-10px; */
  background: #02180D;
  box-shadow: 0px 2px 4px 1px rgba(97, 94, 255, 0.25);
  }

  .text-container{
    color: #00FF83;
  font-family: sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: -15px;
  /* margin-left: -920px; */
  padding-left: 100px;
  /* margin-right: -90px; */
  }

  .centered-image {
    width: 239px;
    height: 231px;
    flex-shrink: 0;
    justify-content: center;
    margin-left: 45px;
    margin-top: 25px;

  }
  
  .new-container {
  border: 1px solid #02180;
  background-color:black;
  padding: 10px; 
  margin-top: 30px; 
  width: 290px; 
  height: 290px;
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-left: 20px;
  background: transparent;
  }

  .new-container {
    margin-top: 20px;
  }


  .dark-icon,
  .light-icon {
    color: #FFFFFF;
    margin-right: 6px;
  }
  
  .dark-link,
  .light-link {
   color: #FFF;
   font-family: sans-serif;
   font-size: 17px;
   font-style: normal;
   font-weight: 300;
   line-height: normal;
  }
  
.contact-image{
  width: 32px;
height: 32px;
color: #FFF;
}



.text2-container{
  color: #FFF;
font-family:op;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 10px;

}

/*.name-input {
  width: 287.522px;
height: 46.806px;
flex-shrink: 0;

  border-radius: 4px;
  border-radius: 5px;
border: 0.4px solid #000;
background: rgba(255, 255, 255, 0.43);
}
*/

.input-box {
  width: 270px;
  padding : 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
border: 0.4px solid #000;
background: rgba(255, 255, 255, 0.43);
  font-size: 16px;
  margin-right: -170px;
  margin-top: 40px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: -2px;
}


.input-box::placeholder {
  color: #FFF;
  font-weight: 200;
}

.input2-box {
  width: 270px;
  padding : 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
border: 0.4px solid #000;
background: rgba(255, 255, 255, 0.43);
  font-size: 16px;
  margin-right: -170px;
  margin-top: 30px;
  margin-left: -2px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
}

.input2-box::placeholder {
  color: #FFF;
  font-weight: 200;
}

.input3-box {
  padding-top: -100px;
  border: 1px solid #ccc;
  border-radius: 5px;
border: 0.4px solid #000;
background: rgba(255, 255, 255, 0.43);
  font-size: 16px;
  margin-right: -170px;
  margin-top: 30px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 423.194px;
height: 155.433px;
margin-left: -2px;
padding-bottom: 120px;
padding-left: 16px;


}

.input3-box::placeholder {
  color: #FFF;
  font-weight: 200;
}

.submit-button {
  color: #FFFFFF;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
background: #00954C;
  font-size: 16px;
  display: inline-flex;
padding: 16px 48px;
justify-content: center;
align-items: center;
gap: 4px;
margin-top: 35px;
margin-left: 90px;
}



/* @media screen and (max-width: 768px) { */
  
  @media screen and (max-width: 800px) {
    .container {
      align-items: center;
      justify-content: center;
    }
  
    .box-container1 {
      width: auto;
      height: auto;
      margin-left: -16px;
      margin-right: 0;
      justify-content: center;
      align-items: center;
    }
  
    .inner-container {
      width: 280px;
      height: auto;
      margin-left: 0;
      margin-right: 0;
      margin-top: 1px;
    }

    .center-container {
      margin-left: 0;
      margin-right: 7px;
      margin-top: 1px;
    }
    
    .image-container {
      margin-left:-1px;
      margin-right:8px;
      margin-top: 1px;
    }
    .new-container {
      margin-left:-1px;
      margin-right:8px;
      margin-top: 5px;
    }
  
    .centered-image {
      margin-left: 20px;
      margin-right: 0;
      justify-content: center;
    }
  
    .text-container {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      text-align: center;
      margin-top: 26px;
    }
  
    .input-box,
    .input2-box,
    .input3-box {
      width: 90%;
      margin: 10px 0;
    }
  
    .submit-button {
      margin-left: 0;
      margin-bottom: 15px;
    }
  /* } */

}