
@media only screen and (max-width:940px){
    .tokenBody{
        flex-direction:column !important;
        align-content:center;
    }
    .tokenSettings{
        border:0 !important; 
        padding-left:0;
        width:100% !important;
    }
    .tokenCreate{
        width:100% !important
    }
}